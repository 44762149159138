<template>
  <vca-column>
    <h3>{{ $t("network.artists.name.label") }}</h3>
    <div v-if="mode == 'edit'">
      <vca-input
        v-model.trim="value.name"
        ref="name"
        :rules="$v.value.name"
        :placeholder="$t('network.artists.name.placeholder')"
        :errorMsg="$t('network.artists.name.errorMsg')"
      />
      <button class="vca-button" @click="add()">
        {{ $t("button.save") }}
      </button>
    </div>
    <ArtistDropdown v-else ref="name" v-model="value.name" @add="add()" />
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
import ArtistDropdown from "@/components/network/artists/ArtistDropdown";
export default {
  name: "ArtistForm",
  components: { ArtistDropdown },
  data() {
    return {
      coe: null,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  computed: {
    ...mapGetters({
      validation: "artists/validations",
    }),
  },
  validations() {
    return this.validation;
  },
  methods: {
    add() {
      if (this.$v.$invalid) {
        this.$refs.name.validate();
      } else {
        this.$emit("submit");
      }
    },
  },
};
</script>
