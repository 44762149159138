<template>
  <vca-card class="shadowed">
    <ArtistForm v-model="create" mode="create" @submit="add()" />
  </vca-card>
</template>

<script>
import ArtistForm from "@/components/network/artists/ArtistForm";
export default {
  name: "ArtistAdd",
  components: { ArtistForm },
  computed: {
    create: {
      get() {
        return this.$store.state.artists.create;
      },
      set(value) {
        this.$store.commit("artists/create", value);
      },
    },
  },
  methods: {
    add() {
      if (!confirm(this.$t("network.artists.messages.add"))) {
        return false;
      }
      this.$store.dispatch({ type: "artists/create" }).then(() => {
        this.create = { name: "" };
      });
    },
  },
};
</script>
