<template>
  <vca-card>
    <h2>{{ $t("network.artists.add.header") }}</h2>
    <Add />
  </vca-card>
</template>

<script>
import Add from "@/components/network/artists/ArtistAdd";
export default {
  name: "NetworkArtistAdd",
  components: { Add },
  created() {
    this.$store.dispatch({ type: "artists/list" });
  },
};
</script>
